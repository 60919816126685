<template>
  <div>
    <v-combobox
      v-model="selectedEmails"
      class="forward-input"
      label="Prześlij do"
      :items="clientContactsWithEmail"
      item-value="email"
      :item-text="item => getFormattedContact(item)"
      placeholder="Wybierz osoby, które mają otrzymać dokument"
      :rules="[emailsValidation, !required || rules.required]"
      :disabled="disabled"
      outlined
      multiple
      dense
    >
      <template #selection="data">
        <v-chip
          :key="JSON.stringify(data.item)"
          :input-value="data.selected"
          :disabled="data.disabled"
          :text-color="getInputColor(data.item, '')"
          small
          v-bind="data.attrs"
        >
          <span> {{ getEmailRole(data.item.email || data.item) }}{{ data.item.email || data.item }} </span>
          <v-icon
            class="ml-2 mr-n1"
            small
            :color="getInputColor(data.item)"
            @click.stop="data.parent.selectItem(data.item)"
          >
            mdi-close-circle
          </v-icon>
        </v-chip>
      </template>
    </v-combobox>
    <Warning
      v-if="warningMessage"
      :message="warningMessage"
      class="mb-2"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import rules from '../../../utils/validators'

export default {
  props: {
    contactEmails: {
      type: Array,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    rules
  }),
  computed: {
    ...mapState({
      clientContacts: state => state.client.entity?.contacts,
    }),
    clientContactsWithEmail() {
      let contacts = this.clientContacts?.filter(contact => !!contact.email) || []
      if (this.type === 'invoice') {
        contacts = contacts.filter(contact => !!contact.invoiceReceivable)
      }
      return contacts
    },
    selectedEmails: {
      get() {
        return this.contactEmails
      },
      set(value) {
        const contactEmails = value.map(email => email.email || email)
        this.$emit('update', contactEmails)
      }
    },
    warningMessage() {
      if (this.disabled) return ''

      if (this.clientContacts?.length === 0) {
        return 'Brak kontaktów do wyświetlenia'
      } else if (this.clientContacts?.length && this.clientContacts.map(contact => contact.email).filter(Boolean).length === 0) {
        return 'Brak kontaktów z adresem e-mail'
      } else if (this.type === 'invoice' && this.clientContacts?.length && this.clientContacts?.map(contact => contact.invoiceReceivable).filter(Boolean).length === 0) {
        return 'Żaden z kontaktów nie ma ustawionego odbioru faktur'
      } else return ''
    }
  },
  methods: {
    getInputColor(item, successColor = 'primary', errorColor = 'error') {
      return rules.email(item.email || item) === 'Niepoprawny e-mail' ? errorColor : successColor
    },
    getEmailRole(email) {
      const contact = this.clientContacts.find(contact => contact.email === email)
      return contact?.role ? contact?.role + ' - ' : ''
    },
    getFormattedContact(contact) {
      return (contact?.role ? contact.role + ' - ' : '') + contact.fullName + ' - ' + contact.email
    },
    emailsValidation(values) {
      return values.every(
        contact => rules.email(contact.email || contact) !== 'Niepoprawny e-mail'
      ) || 'Wprowadzono niepoprawny email'
    },
  }
}
</script>

<style lang="scss" scoped>
.forward-input::v-deep  .v-input__slot {
  height: unset !important;
  .v-chip__content > span {
    text-overflow: ellipsis;
    overflow:hidden;
  }

  .v-chip{
    margin: 4px;
  }
}
</style>
