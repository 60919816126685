<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="generatePaymentLinkForm"
        ref="generatePaymentLinkForm"
        @submit.prevent="submitPaymentLinkForm"
      >
        <v-row class="pt-4">
          <v-col
            v-if="isChangingLinkTypeEnabled"
            cols="12"
          >
            <v-autocomplete
              v-model="paymentLinkType"
              label="Rodzaj wysyłki linku"
              :items="paymentLinkTypes"
              item-text="label"
              item-value="value"
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row v-if="paymentLinkType === 'text'">
          <v-col cols="12">
            <div class="d-flex ga-3">
              <v-text-field
                v-model="tempPhoneNumberValue"
                :rules="[rules.phoneNumber]"
                v-mask="getFieldMask('phoneNumber')"
                label="Numer telefonu"
                placeholder="Dodaj numer telefonu"
                validate-on-blur
                outlined
                @keydown.enter.prevent="addPhoneNumber(tempPhoneNumberValue)"
              />
              <v-btn
                class="ml-2"
                icon
                medium
                color="primary"
                target="_blank"
                @click="addPhoneNumber(tempPhoneNumberValue)"
              >
                <v-icon medium>
                  mdi-plus
                </v-icon>
              </v-btn>
            </div>
            <div class="phone-numbers-list">
              <span class="phone-numbers-list__label">Wybrane numery telefonów</span>
              <div
                class="phone-numbers-list__value"
                v-for="(number, index) in params.phoneNumbers"
                :key="`${index}-${number}`"
              >
                <span>{{ number }}</span>
                <v-icon
                  size="20"
                  color="red"
                  @click="removePhoneNumber(index)"
                >
                  mdi-close
                </v-icon>
              </div>
            </div>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="params.title"
              :rules="[rules.required]"
              label="Tytuł wiadomości"
              placeholder="Wpisz tytuł wiadomości"
              validate-on-blur
              outlined
            />
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="params.description"
              :rules="[rules.required]"
              label="Wiadomość"
              placeholder="Wpisz wiadomość"
              validate-on-blur
              outlined
            />
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12">
            <v-text-field
              v-model="params.clientEmail"
              :rules="[rules.required, rules.email]"
              label="E-mail klienta"
              placeholder="Wpisz e-mail"
              validate-on-blur
              outlined
            />
          </v-col>
          <v-col cols="12">
            <ForwardDocument
              :contact-emails="params.contactEmails"
              required
              @update="updateContacts"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              :value="paymentValue"
              label="Kwota"
              outlined
              disabled
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        :disabled="!isFormComplete"
        type="submit"
        form="generatePaymentLinkForm"
        v-shortkey="{ enter: ['enter'] }"
        @shortkey.native="submitPaymentLinkForm"
      >
        Wyślij link
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import ForwardDocument from '../../components/Forms/Partials/ForwardDocument'
import afterFormSubmitted from '../../mixins/afterFormSubmitted'
import rules from '../../utils/validators'
import { mapState, mapActions } from 'vuex'
import { formatValue } from '../../utils/typesEnum'
import { getFieldMask } from '../../utils'

export default {
  components: {
    DialogFormWrapper,
    ForwardDocument
  },
  mixins: [afterFormSubmitted],
  data: () => ({
    rules,
    params: {
      clientEmail: '',
      contactEmails: [],
      orderIds: [],
      phoneNumbers: [],
      title: '',
      description: ''
    },
    tempPhoneNumberValue: '',
    paymentLinkType: 'mail'
  }),
  computed: {
    ...mapState({
      selectedItems: state => state.orders.selectedItems,
      isProcessing: state => state.orders.isProcessing,
      orders: state => state.orders.items,
      order: state => state.layout.dialog.item,
      dialog: state => state.layout.dialog.data,
      client: state => state.client.entity
    }),
    paymentLinkTypes() {
      return [
        { value: 'mail', label: 'E-mail' },
        { value: 'text', label: 'SMS' }
      ]
    },
    isChangingLinkTypeEnabled() {
      return this.dialog.mode === 'generate'
    },
    selectedOrders() {
      return this.orders.filter(order => this.selectedItems.includes(order.id))
    },
    paymentValue() {
      // for single order
      if (this.order) {
        const { totalGrossValue, paidValue } = this.order?.payment || {}
        return totalGrossValue !== undefined && paidValue !== undefined
          ? formatValue(totalGrossValue - paidValue, 'grossValue')
          : '-'
      } else {
        // for multiple orders
        let totalGrossValueSum = 0
        let paidValueSum = 0

        this.selectedItems.forEach(id => {
          const order = this.orders.find(order => order.id === id)
          if (order) {
            const { totalGrossValue, paidValue } = order || {}
            totalGrossValueSum += totalGrossValue
            paidValueSum += paidValue
          } else {
            return '-'
          }
        })
        return totalGrossValueSum && paidValueSum ? formatValue(totalGrossValueSum - paidValueSum, 'grossValue') : '-'
      }
    },
    isPaymentTypeLinkMail() {
      return this.paymentLinkType === 'mail'
    },
    isFormComplete() {
      if (this.isPaymentTypeLinkMail) {
        return this.params.clientEmail && this.params.contactEmails.length
      } else {
        return this.params.phoneNumbers.length && this.params.title && this.params.description
      }
    }
  },
  created() {
    this.params.orderIds = this.selectedItems.length ? [...this.selectedItems] : [this.order.id]
    const { payer, payerId } = this.selectedOrders[0] || this.order
    this.getClient(payerId || payer.id) // to get client contacts in ForwardDocument
      .then(() => {
        this.params.phoneNumbers = this.client.phoneNumber ? [this.client.phoneNumber] : []
        this.params.clientEmail = this.client.email

        const { linkSettlement } = this.dialog

        if (linkSettlement) {
          const { paymentLink } = linkSettlement
          this.paymentLinkType = paymentLink.paymentLinkType
          this.params.paymentLinkId = paymentLink.id

          if (paymentLink.phoneNumbers.length) {
            this.params.phoneNumbers = [...paymentLink.phoneNumbers]
          }
          delete this.params.orderIds
        }
      })
  },
  methods: {
    ...mapActions({
      getClient: 'client/getSingleClient',
      sendPaymentLinkByMail: 'orders/sendPaymentLinkByMail',
      sendPaymentLinkBySMS: 'orders/sendPaymentLinkBySMS',
      toggleMultiselectStatus: 'orders/toggleMultiselectStatus'
    }),
    getFieldMask,
    updateContacts(contactEmails) {
      this.params.contactEmails = contactEmails
    },
    addPhoneNumber(phoneNumber) {
      if (
        typeof rules.phoneNumber(phoneNumber) === 'string' ||
        this.params.phoneNumbers.includes(phoneNumber) ||
        !phoneNumber
      ) { return }
      this.params.phoneNumbers.push(phoneNumber)
      this.tempPhoneNumberValue = ''
    },
    removePhoneNumber(index) {
      this.params.phoneNumbers.splice(index, 1)
    },
    submitPaymentLinkForm() {
      if (this.$refs.generatePaymentLinkForm.validate()) {
        const { mode } = this.dialog

        const params = {
          orderIds: this.params.orderIds
        }

        let sendFunction = null

        if (this.isPaymentTypeLinkMail) {
          sendFunction = this.sendPaymentLinkByMail
          params.clientEmail = this.params.clientEmail
          params.contactEmails = this.params.contactEmails
        } else {
          sendFunction = this.sendPaymentLinkBySMS
          params.phoneNumbers = this.params.phoneNumbers
          params.title = this.params.title
          params.description = this.params.description
        }

        if (['resend', 'regenerate'].includes(mode)) {
          params.paymentLinkId = this.dialog.linkSettlement?.paymentLink?.id
        }

        sendFunction({ ...params, mode }).then(() => {
          this.afterFormSubmitted('Przesłano link do płatności')
          this.toggleMultiselectStatus(false)
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.phone-numbers-list {
  margin: 8px 0 24px 0;
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow-y: scroll;
  background: rgba(55, 200, 122, 0.1);
  border-radius: 4px;
  padding: 10px 16px;

  &::-webkit-scrollbar-track {
    background: transparent !important;
  }

  &__label {
    font-weight: 800;
    font-size: 12px;
    display: block;
    color: $color-text-dark-grey;
  }

  &__value {
    display: flex;
    justify-content: space-between;
  }
}
</style>
